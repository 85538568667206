import mockup_1 from'../../../assets/images/mockup_1.png'
import mockup_2 from'../../../assets/images/mockup_2.png'
import apple from'../../../assets/images/apple.png'
import google from'../../../assets/images/google.png'
import './style.css'

function Header() {
    return (
        <section className='header'>
            <div className='container relative header-content'>
                <div className='mockups'>
                    <img className='mockup_1-img' src={ mockup_1 } alt="Mockup img" />
                    <img className='mockup_2-img' src={ mockup_2 } alt="Mockup img" />
                </div>
                <div className='header-text'>
                    <span className='title'>Jogaê</span>
                    <span className='text'>Seja bem vindo ao app que vai mudar o jeito como você organiza suas peladas</span>
                    <span className='text hide-mobile'>Crie clubes, marque jogos e junte a galera facilmente</span>
                    <span className='text hide-mobile'>Veja como você e sua galera está jogando e melhore seu desempenho</span>
                    <div className='download'>
                        <a href="https://apps.apple.com/br/app/joga%C3%AA/id6615070287" target='_blank' rel="noreferrer">
                            <img src={ apple } alt="Apple link" />
                        </a>
                        
                        <a href="https://play.google.com/store/apps/details?id=com.htgabriel.matchmakers" target='_blank' rel="noreferrer">
                            <img src={ google } alt="Google link" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header;

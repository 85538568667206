import { Navigate, Route, Routes as Switch } from "react-router-dom"
import { BrowserRouter } from "react-router-dom"
import Home from "../../pages/home/index"
import DeleteAccount from "../../pages/delete-account/index";

function Routes() {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" element={<Home />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;

import React, { useState } from 'react';
import { FaInstagram, FaEnvelope, FaWhatsapp } from "react-icons/fa6";

import './style.css'

function Menu() {
    const [isMobileMenuChecked, setIsMobileMenuChecked] = useState(false);

    const handleLinkClick = () => {
        setIsMobileMenuChecked(false);
    };
    return(
        <div className='menuWrapper'>
            <input type='checkbox' id='menu_mobile' checked={isMobileMenuChecked} onChange={() => setIsMobileMenuChecked(!isMobileMenuChecked)}/>
            <div>
                <label htmlFor='menu_mobile' className='burgerIcon'>
                    <span></span>
                    <span></span>
                    <span></span>
                </label>
                <div className="menu">
                    <div className='menu-items'>
                        <a href='#contact' onClick={handleLinkClick}>
                            <span>01. </span> Contato
                        </a>
                    </div>

                    <div className='mobile-contact'>
                        <a href="https://www.instagram.com/appjogae" target="_blank" rel="noreferrer">
                            <FaInstagram />
                        </a>        
                        <a href="mailto:appjogae@gmail.com">
                            <FaEnvelope />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5532991241448&text=Ol%C3%A1%20Gostaria%20de%20fazer%20algumas%20perguntas%20sobre%20o%20App%20Jogae" target="_blank" rel="noreferrer">
                            <FaWhatsapp />
                        </a>                        
                    </div>
                </div>
                <label htmlFor='menu_mobile' className='menu-backdrop' />
            </div>
        </div>
    )
}

export default Menu;
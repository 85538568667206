import logo from'../../../assets/images/logo.jpg'
import './style.css'

function Logo() {
    return (
        <a className="logo" href='/#home'>
            <img className="logo-img" src={logo} alt="logo" />
        </a>
    )
}

export default Logo;
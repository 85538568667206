import Navbar from '../../shared/components/molecules/navbar/index.js';
import Header from '../../shared/components/organisms/header/index.js';
import Footer from '../../shared/components/organisms/footer/index.js';

function Home() {
    return (
        <div id="home" className="page">
            <Navbar />
            <Header />
            <Footer />
        </div>
    )
}

export default Home;

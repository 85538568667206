import React from 'react'
import Navbar from '../../shared/components/molecules/navbar/index.js';
import Footer from '../../shared/components/organisms/footer/index.js';
import DeleteAccount from '../../shared/components/organisms/deleteAccount/index.js';

const AccountDeletionPage = () => {
	return (
		<div id="deleteAccount" className="page">
            <Navbar />
			<DeleteAccount />
            <Footer />
		</div>		
	)
};

export default AccountDeletionPage;
